* {
	scroll-behavior: smooth;
}

body {
	margin: 0;
}

@font-face {
	font-family: 'Pixel';
	src: url('../fonts/Pixel-LCD-7.woff') format('woff');
}

.ReactModal__Overlay {
}

/*
.ReactModal__Overlay {
	opacity: 0;
}

.ReactModal__Content--after-open {
	animation: bounce 1s ease;
	transition: all 1000ms ease-in-out;
}

.ReactModal__Content--before-close {
	transform: scale(0.5);
}

.ReactModal__Overlay--after-open {
	opacity: 1;
}

@keyframes bounce {
	0% {
		transform: scale(0.5);
	}
	100% {
		transform: scale(1);
	}
}
*/
